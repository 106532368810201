import { gql } from '@apollo/client';

const getUserData = gql`
  query getUserData($userId: ID!) {
    user(where: { id: $userId }) {
      id
      username
      name
      cumulativeTimePoints
      shouldStoreArticlesOnArweave
      publicAddress
      twitterHandle
      isConnectedWithLens
      email
      emailVerified
      bio
      bioLink
      profileImage {
        id
        publicUrl
        publicUrlTransformed
        _meta {
          public_id
          height
          width
        }
      }
      settingsNotificationReplyToPrompt
      settingsNotificationNewComment
      settingsNotificationReplyToComment
      settingsNotificationReplyToQuote
      settingsNotificationPostCollect
    }
  }
`;

export default getUserData;
