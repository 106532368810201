import { PrivyEvents } from '@privy-io/react-auth';
import { useAnalytics } from 'src/components/AnalyticsProvider/AnalyticsProvider';

type TUseSignInAnalyticsEventsResult = {
  onComplete: PrivyEvents['login']['onComplete'];
  onError: PrivyEvents['login']['onError'];
  onLoginInitiated: () => void;
};

export const useSignInAnalyticsEvents = (): TUseSignInAnalyticsEventsResult => {
  const { sendEvent } = useAnalytics();

  const onComplete: PrivyEvents['login']['onComplete'] = (_user, isNewUser, wasAlreadyAuthenticated) => {
    sendEvent('sign_in_success', {
      is_new_user: isNewUser,
      was_already_authenticated: wasAlreadyAuthenticated,
    });
  };

  const onError: PrivyEvents['login']['onError'] = () => {
    sendEvent('sign_in_error');
  };

  const onLoginInitiated = () => {
    sendEvent('sign_in_initiated');
  };

  return { onComplete, onError, onLoginInitiated };
};
