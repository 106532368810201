import { useRouter } from 'next/compat/router';
import { GetServerSideProps } from 'next';
import { useEffect, useState } from 'react';
import { NextSeo } from 'next-seo';
import { usePrivy } from 't2-keystone/packages/privyAuthentication/src/hooks/usePrivy';
import { User } from '@privy-io/react-auth';
import { TPrivyLoginResult } from 't2-keystone/packages/privyAuthentication/src/types';
import { useSignInAnalyticsEvents } from '../hooks/useSignInAnalyticsEvents';
import { showNotification } from '../components/NotificationBanner/showNotification';
import { isRequestAuthenticated } from '../components/Auth/services/isRequestAuthenticated';
import Button from '../components/Button';
import Navbar from '../components/Navbar/Navbar';
import { useAuthenticatedUser } from '../components/Auth/hooks/useAuthenticatedUser';
import { getFeatureFlag } from '../components/FeatureFlags/getFeatureFlag';
import { useLensIntegrationBanner } from '../components/LensIntegrationBanner/hooks/useLensIntegrationBanner';
import Spinner from '../components/Spinner/Spinner';
import { useBlockForDeactivatedUser } from '../components/BlockForDeactivatedUser/hooks/useBlockForDeactivatedUser';
import { VerifyEmail } from '../components/VerifyEmail/VerifyEmail';

const SignInPage = () => {
  const router = useRouter();
  const { onLoginInitiated, ...loginOptions } = useSignInAnalyticsEvents();
  const { ready, authenticated, login, logout } = usePrivy({ loginOptions });
  const { refetch: fetchAuthenticatedItem } = useAuthenticatedUser();
  const { showLensIntegrationBanner } = useLensIntegrationBanner();
  const { showNotificationForBlockedUser } = useBlockForDeactivatedUser();
  const getRedirectUrl = () => (typeof router?.query?.redirect === 'string' ? router?.query.redirect : '/');
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);

  const onLoginSuccess = async (privyUser: TPrivyLoginResult) => {
    const authenticatedItemResponse = await fetchAuthenticatedItem();
    const authenticatedUser = authenticatedItemResponse?.data?.user;
    const isUserConnectedWithLens = !!authenticatedUser?.isConnectedWithLens;
    const isUserDeactivated = authenticatedUser?.deactivated;
    const isNewUser = privyUser?.isNewUser;
    const userEmail = authenticatedUser?.email;

    if (isUserDeactivated) {
      showNotificationForBlockedUser();
      await logout();
    }
    const redirectUrl = getRedirectUrl();

    if (isNewUser && !userEmail) {
      setShowVerifyEmail(true);
    } else {
      router?.push(redirectUrl);
    }

    if (getFeatureFlag('lensIntegration') && !isUserConnectedWithLens) {
      await showLensIntegrationBanner(privyUser as unknown as User);
    }
  };

  const onError = () => {
    showNotification({
      title: 'Error',
      message: 'There was a problem with logging in. Please try again.',
      type: 'error',
    });
  };

  const withErrorHandler =
    (fn: Function) =>
    async (...args: any[]) => {
      try {
        await fn(...args);
      } catch (err) {
        onError(err);
      }
    };

  const handleLogin = async () => {
    onLoginInitiated();
    const user = await login();
    await onLoginSuccess(user);
  };

  useEffect(() => {
    if (ready) {
      withErrorHandler(handleLogin)();
    }
  }, [ready]);

  return (
    <>
      <NextSeo title="Sign in" />
      <div className="bg-white-100 w-screen h-screen flex items-center justify-center">
        <Navbar showNotifications={false} showLoginButton={false} showUserMenu={false} themeVariant="light" />
        {ready && !authenticated ? (
          <Button
            onClick={withErrorHandler(handleLogin)}
            variant="primary"
            title="Sign in"
            className="font-secondary !font-normal !rounded-[30px] !px-12"
          />
        ) : (
          <Spinner />
        )}
        {showVerifyEmail && <VerifyEmail redirectUrl={getRedirectUrl()} />}
      </div>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  if (await isRequestAuthenticated(context.req)) {
    const destination = typeof context.query.redirect === 'string' ? context.query.redirect : '/';

    return {
      redirect: {
        destination,
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};

export default SignInPage;
