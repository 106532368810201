import { useEffect, useMemo, useState } from 'react';
import { usePrivy, User } from '@privy-io/react-auth';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { captureException } from '../../services/monitoring/captureException';
import { verifyEmailMutation } from '../User/mutations/verifyEmailMutation';
import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { showNotification } from '../NotificationBanner/showNotification';
import getUserData from '../UserProfileSection/queries/getUserData';

type VerifyEmailProps = {
  redirectUrl?: string;
  onClose?: (show: boolean) => void;
};

const enum EmailVerificationSteps {
  LinkEmailStarted,
  LinkEmailSucceeded,
  LinkEmailVerified,
}

const getPrivyEmail = (privyUser: User | null) => privyUser?.email?.address;
const hasPrivyEmail = (privyUser: User | null) => !!getPrivyEmail(privyUser);

export const VerifyEmail = ({ redirectUrl, onClose }: VerifyEmailProps) => {
  const [prevSteps, setPrevSteps] = useState<EmailVerificationSteps[]>([]);
  const router = useRouter();
  const { authenticatedUserId } = useReactiveAuthenticatedUser() || {};
  const { linkEmail, user: privyUser, ready, authenticated, unlinkEmail, isModalOpen } = usePrivy();
  const [verifyEmail] = useMutation(verifyEmailMutation, {
    refetchQueries: [
      {
        query: getUserData,
        variables: {
          userId: authenticatedUserId,
        },
      },
    ],
  });

  const hasStartedLinkEmailProcess = useMemo(
    () => prevSteps.includes(EmailVerificationSteps.LinkEmailStarted),
    [prevSteps],
  );

  const hasSucceededLinkEmail = useMemo(
    () => prevSteps.includes(EmailVerificationSteps.LinkEmailSucceeded),
    [prevSteps],
  );

  const hasVerifiedLinkEmail = useMemo(() => prevSteps.includes(EmailVerificationSteps.LinkEmailVerified), [prevSteps]);

  const onError = async (error: any) => {
    captureException(error);
    const privyUserEmail = getPrivyEmail(privyUser);

    if (privyUserEmail) {
      try {
        await unlinkEmail(privyUserEmail);
      } catch (error) {
        captureException(error);
      }
    }

    if (redirectUrl) {
      router?.push(redirectUrl);
    }

    showNotification({
      message: 'There was a problem with verifying your email. Please try again.',
      type: 'error',
    });
  };

  useEffect(() => {
    const verifyEmailStep = async () => {
      if (ready && authenticated && hasSucceededLinkEmail) {
        try {
          await verifyEmail();
          setPrevSteps((steps) => [...steps, EmailVerificationSteps.LinkEmailVerified]);
          if (redirectUrl) {
            router?.push(redirectUrl);
          }
        } catch (error) {
          onError(error);
        }
      }
    };
    verifyEmailStep();
  }, [privyUser, ready, authenticated, prevSteps]);

  useEffect(() => {
    if (hasPrivyEmail(privyUser)) {
      setPrevSteps((steps) => [...steps, EmailVerificationSteps.LinkEmailSucceeded]);
    }
  }, [privyUser]);

  useEffect(() => {
    if (!hasStartedLinkEmailProcess && ready) {
      try {
        if (!hasPrivyEmail(privyUser)) {
          linkEmail();
          setPrevSteps((steps) => [...steps, EmailVerificationSteps.LinkEmailStarted]);
        }
      } catch (error: any) {
        onError(error);
      }
    }
  }, [hasStartedLinkEmailProcess, ready]);

  useEffect(() => {
    if (!isModalOpen && hasStartedLinkEmailProcess && hasVerifiedLinkEmail) {
      onClose?.(false);
    }
  }, [hasStartedLinkEmailProcess, isModalOpen, hasVerifiedLinkEmail]);

  return null;
};
